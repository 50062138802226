import React, { useState } from 'react'
import './PageNav.scss'
import { NavLink } from 'react-router-dom'

export default function PageNav() {
    const [openNav, setOpenNav] = useState(false)

    const handleOpenNav = () => [
        setOpenNav(true)
    ]

    const handleCloseNav = () => {
        setOpenNav(false)
    }

    return (
        <nav className='nav'>
            <div className={`nav--closed ${openNav ? 'nav--open' : ''}`}>
                <div className='nav__close' onClick={handleCloseNav}>X</div>
                <NavLink to='/' onClick={handleCloseNav} className='nav__link'>HOME</NavLink>
                <NavLink to='/projects' onClick={handleCloseNav} className='nav__link'>PROJECTS</NavLink>
                <NavLink to='/about' onClick={handleCloseNav} className='nav__link'>ABOUT</NavLink>
                <NavLink to='/contact' onClick={handleCloseNav} className='nav__link'>CONTACT</NavLink>
            </div>
            <div className="nav__burger-container" onClick={handleOpenNav}>
                <div className="bar1"></div>
                <div className="bar2"></div>
            </div>
        </nav>
    )
}