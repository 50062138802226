import React, { useState, useRef } from 'react'
import './HomePage.scss'
import Typewriter from 'typewriter-effect'
import arrow from '../../assets/1144832-200.png'
import { SliceVideoMobile, SliceVideoTablet, SliceVideoDesktop, NetubeVideoMobile, NetubeVideoTablet, NetubeVideoDesktop, CGVideoMobile } from '../../components/ProjectVideo'

const HomePage = () => {
  const [mobile, setMobile] = useState(true)
  const [tablet, setTablet] = useState(false)
  const [desktop, setDesktop] = useState(false)

  const ref = useRef(null)
  const refTwo = useRef(null)
  const refThree = useRef(null)

  const handleScroll = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
    setMobile(true)
    setTablet(false)
    setDesktop(false)
  }
  const handleScrollHighlights = () => {
    refTwo.current?.scrollIntoView({ behavior: 'smooth' })
    setMobile(true)
    setTablet(false)
    setDesktop(false)
  }
  const handleScrollThree = () => {
    refThree.current?.scrollIntoView({ behavior: 'smooth' })
    setMobile(true)
    setTablet(false)
    setDesktop(false)
  }

  const handleMobile = () => {
    setMobile(true)
    setTablet(false)
    setDesktop(false)
  }
  const handleTablet = () => {
    setMobile(false)
    setTablet(true)
    setDesktop(false)
  }
  const handleDesktop = () => {
    setMobile(false)
    setTablet(false)
    setDesktop(true)
  }

  return (
    <>
      <div className='homepage'>
        <Typewriter

          onInit={(typewriter) => {
            typewriter.typeString("<h1>Welcome</h1>")
              .pauseFor(2000)
              .deleteAll()
              .typeString('<h1>This is my portfolio.</h1>')
              .changeDelay('natural')
              .start()
          }}

        />
        <div className='homepage__scroll'>
          <p>Click here to see my highlighted projects.</p>
          <div className='homepage__scroll-container'>
            <img onClick={handleScroll} src={arrow} alt='scroll down arrow' className='homepage__scroll-arrow' />
          </div>
        </div>
      </div>

      {/* PROJECT SLICE */}
      <div className='homepage__highlight' ref={ref}>
        <div className='homepage__highlight-best'>
          <div>
            <h1 className='homepage__highlight-title'>Slice.</h1>
            <h4 className='homepage__highlight-subtitle'>(Capstone - Full Stack Developer)</h4>
          </div>
          {mobile ?
            <SliceVideoMobile />
            : ''}
          {tablet ?
            <SliceVideoTablet />
            : ''}
          {desktop ?
            <SliceVideoDesktop />
            : ''}
          <div className='homepage__highlight-selectors'>
            <span onClick={handleMobile}>Mobile</span>
            <span onClick={handleTablet}>Tablet</span>
            <span onClick={handleDesktop}>Desktop</span>
          </div>
          <div className='homepage__scroll'>
            <img onClick={handleScrollHighlights} src={arrow} alt='scroll down arrow' className='homepage__scroll-arrow' />
          </div>
        </div>
      </div>

      {/* PROJECT NETUBE */}
      <div className='homepage__highlight' ref={refTwo}>
        <div className='homepage__highlight-best'>
          <div>
            <h1 className='homepage__highlight-title'>Netube.</h1>
            <h4 className='homepage__highlight-subtitle'>(Paired Programming - Front-End Developer)</h4>
          </div>

          {mobile ?
            <NetubeVideoMobile />
            : ''}
          {tablet ?
            <NetubeVideoTablet />
            : ''}
          {desktop ?
            <NetubeVideoDesktop />
            : ''}
          <div className='homepage__highlight-selectors'>
            <span onClick={handleMobile}>Mobile</span>
            <span onClick={handleTablet}>Tablet</span>
            <span onClick={handleDesktop}>Desktop</span>
          </div>
          <div className='homepage__scroll'>
            <img onClick={handleScrollThree} src={arrow} alt='scroll down arrow' className='homepage__scroll-arrow' />
          </div>
        </div>
      </div>

      {/* PROJECT CG */}
      <div className='homepage__highlight' ref={refThree}>
        <div className='homepage__highlight-best'>
          <div>
            <h1 className='homepage__highlight-title'>Canada Goose.</h1>
            <h4 className='homepage__highlight-subtitle'>(Industry Project - Lead Developer)</h4>
          </div>

          {mobile ?
            <CGVideoMobile />
            : ''}
          <div className='homepage__highlight-selectors'>
            <span onClick={handleMobile}>Mobile</span>
          </div>
          <div className='homepage__scroll'>
            <img onClick={handleScroll} src={arrow} alt='scroll down arrow ' className='homepage__scroll-arrow--reverse' />
          </div>
        </div>
      </div>
    </>

  )
}
export default HomePage
