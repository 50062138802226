import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import PageNav from './components/PageNav/PageNav';
import HomePage from './pages/HomePage/HomePage';
import ProjectsPage from './pages/ProjectsPage/ProjectsPage';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <PageNav />
        <Switch>
          <Route path='/' exact component={HomePage} />
          <Route path='/projects' exact component={ProjectsPage} />
          <Route path='/about' exact component={AboutPage} />
          <Route path='/contact' exact component={ContactPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
