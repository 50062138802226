export const Icons  = [
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg',
        url: 'https://reactjs.org'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg',
        url: 'https://sass-lang.com'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg',
        url: 'https://nodejs.org/en/'
    },
    {
        image: 'https://camo.githubusercontent.com/93b32389bf746009ca2370de7fe06c3b5146f4c99d99df65994f9ced0ba41685/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f676574706f73746d616e2f676574706f73746d616e2d69636f6e2e737667',
        url: 'https://postman.com'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg',
        url: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript'
    },
    {
        image: 'https://camo.githubusercontent.com/ce0a32825268b09cd5e0fc7c2a09c587a708491427cb794cade8f1866f7284c6/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6a6573746a73696f2f6a6573746a73696f2d69636f6e2e737667',
        url: 'https://jestjs.io'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/electron/electron-original.svg',
        url: 'https://www.electronjs.org'
    },
    {
        image: 'https://camo.githubusercontent.com/fbfcb9e3dc648adc93bef37c718db16c52f617ad055a26de6dc3c21865c3321d/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667',
        url: 'https://git-scm.com'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg',
        url: 'https://redux.js.org'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg',
        url: 'https://www.mysql.com'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg',
        url: 'https://www.w3.org/html/'
    },
    {
        image: 'https://camo.githubusercontent.com/df12cb598044a3f38efc1f45e3580558c324cf8789b79487125044eeebcc4dee/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6865726f6b752f6865726f6b752d69636f6e2e737667',
        url: 'https://dashboard.heroku.com/'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg',
        url: 'https://www.w3schools.com/css/'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg',
        url: 'https://www.typescriptlang.org'
    },
    {
        image: "https://www.netlify.com/v3/img/components/logomark.png",
        url: 'https://www.netlify.com'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg',
        url: 'https://expressjs.com'
    },
    {
        image: 'https://raw.githubusercontent.com/devicons/devicon/d00d0969292a6569d45b06d3f350f463a0107b0d/icons/webpack/webpack-original-wordmark.svg',
        url: 'https://webpack.js.org'
    }
]