import React from 'react'
import sliceMobile from '../assets/videos/slice_mobile_view.mov'
import sliceTablet from '../assets/videos/slice_tablet_video.mov'
import sliceDesktop from '../assets/videos/Untitled design-2.mp4'
import netubeMobile from '../assets/videos/netube_mobile_video.mov'
import netubeTablet from '../assets/videos/netube_tablet_video.mov'
import netubeDesktop from '../assets/videos/netube_desktop_video.mp4'
import cgMobile from '../assets/videos/cg_mobile_video.mov'

// ---------- Slice ----------

export function SliceVideoMobile() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video' >
                <source src={sliceMobile} type='video/mp4' />
            </video>
        </div>
    )
}
export function SliceVideoTablet() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video--tablet' >
                <source src={sliceTablet} type='video/mp4' />
            </video>
        </div>
    )
}
export function SliceVideoDesktop() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video--desktop' >
                <source src={sliceDesktop} type='video/mp4' />
            </video>
        </div>
    )
}

// ---------- Netube ----------
export function NetubeVideoMobile() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video' >
                <source src={netubeMobile} type='video/mp4' />
            </video>
        </div>
    )
}
export function NetubeVideoTablet() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video--tablet' >
                <source src={netubeTablet} type='video/mp4' />
            </video>
        </div>
    )
}
export function NetubeVideoDesktop() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video--desktop' >
                <source src={netubeDesktop} type='video/mp4' />
            </video>
        </div>
    )
}

// ---------- Canada Goose ----------
export function CGVideoMobile() {
    return (
        <div className='homepage__highlight-video-container'>
            <video autoPlay muted loop className='homepage__highlight-video' >
                <source src={cgMobile} type='video/mp4' />
            </video>
        </div>
    )
}
