import React from 'react';
import './ContactPage.scss';
import pfp from '../../assets/pfp.jpeg'
import linkedin from '../../assets/61109.png'
import github from '../../assets/25231-2.png'
import email from '../../assets/761755.png'

const ContactPage = () => {
    return (
        <div className='contact'>
            <h1>Connect With Me!</h1>
            <div className='contact__info'>
                <img src={pfp} alt='klevis doga' className='contact__info-image' />
                <div className='contact__info-divider'></div>
                <div className='contact__info-text'>
                    <div>
                        <a href='https://www.linkedin.com/in/klevisdoga/' rel='noreferrer' target='_blank'>
                            <img src={linkedin} alt='linkedin icon' className='contact__info-text-icon contact__info-text-icon--linkedin' />
                        </a>
                        <a href='https://www.linkedin.com/in/klevisdoga/' rel='noreferrer' target='_blank'>
                            <h2 className='contact__info-text-title contact__info-text-title--linkedin'>My Linkedin</h2>
                        </a>
                    </div>
                    <div>
                        <a href='https://github.com/klevisdoga' rel='noreferrer' target='_blank'>
                            <img src={github} alt='github icon' className='contact__info-text-icon contact__info-text-icon--github' />
                        </a>
                        <a href='https://github.com/klevisdoga' rel='noreferrer' target='_blank'>
                            <h2 className='contact__info-text-title contact__info-text-title--github'>My Github</h2>
                        </a>
                    </div>
                    <div>
                        <a href='mailto: klevisdoga@gmail.com'>
                            <img src={email} alt='email icon' className='contact__info-text-icon contact__info-text-icon--email' />
                        </a>
                        <a href='mailto: klevisdoga@gmail.com'>
                            <h2 className='contact__info-text-title contact__info-text-title--email'>My Email</h2>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ContactPage