import React from 'react'
import './ProjectsPage.scss'
import github from '../../assets/github_icon.png';
import heroku from '../../assets/heroku_icon.webp';
import slicePoster from '../../assets/slice_poster.png'
import kboxPoster from '../../assets/kbox_poster.png'
import netubePoster from '../../assets/netube_poster.png'
import cgPoster from '../../assets/cg_poster.png'

export default function ProjectsPage() {

    return (
        <div className='projects'>
            <div className='projects__independant'>
                <h1 className='projects__independant-title'>Independant</h1>
                <div className='projects__project-container'>
                    <div className='projects__project-info'>
                        <h2 className='projects__project-title'>Slice.</h2>
                        <p>Online-subscription <br/> Manager</p>
                        <div className='projects__links'>
                            <a href='https://github.com/klevisdoga/Slice_Client-capstone' rel="noreferrer" target={'_blank'}>
                                <img src={github} alt='github icon' />
                                <span>CODE</span>
                            </a>
                            <a href='https://kd-slice.herokuapp.com' rel='noreferrer' target='_blank'>
                                <img src={heroku} alt='heroku icon' />
                                <span>SITE</span>
                            </a>
                        </div>
                    </div>
                    <a href='https://kd-slice.herokuapp.com' rel='noreferrer' target='_blank'><img src={slicePoster} alt='slice poster' className='projects__project-poster'/></a>
                </div>
                <div className='projects__project-container projects__project-container--kbox'>
                    <div className='projects__project-info'>
                        <h2 className='projects__project-title'>KBOX.</h2>
                        <p>Xbox Landing Page <br/> CLONE</p>
                        <div className='projects__links'>
                            <a href='https://github.com/klevisdoga/kbox' rel="noreferrer" target={'_blank'}>
                                <img src={github} alt='github icon' />
                                <span>CODE</span>
                            </a>
                            <a href='https://kbox-clone.herokuapp.com' rel='noreferrer' target='_blank'>
                                <img src={heroku} alt='heroku icon' />
                                <span>SITE</span>
                            </a>
                        </div>
                    </div>
                    <a href='https://kbox-clone.herokuapp.com' rel='noreferrer' target='_blank'><img src={kboxPoster} alt='slice poster' className='projects__project-poster'/></a>
                </div>
            </div>
            <div className='projects__collaborative'>
                <h1 className='projects__collaborative-title'>Collaborative</h1>
                <div className='projects__project-container'>
                    <div className='projects__project-info'>
                        <h2 className='projects__project-title'>Netube.</h2>
                        <p>Movie Streaming <br/> Service</p>
                        <div className='projects__links'>
                            <a href='https://github.com/klevisdoga/noah-klevis-NetTube' rel='noreferrer' target='_blank' >
                                <img src={github} alt='github icon' />
                                <span>CODE</span>
                            </a>
                            <a href='https://netube.herokuapp.com' rel='noreferrer' target='_blank'>
                                <img src={heroku} alt='heroku icon' />
                                <span>SITE</span>
                            </a>
                        </div>
                    </div>
                    <a href='https://netube.herokuapp.com' rel='noreferrer' target='_blank'><img src={netubePoster} alt='slice poster' className='projects__project-poster'/></a>
                </div>
                <div className='projects__project-container projects__project-container--cg'>
                    <div className='projects__project-info projects__project-info--cg'>
                        <h2 className='projects__project-title'>Canada Goose.</h2>
                        <p>Personalized Online-shopping <br/> Experience</p>
                        <div className='projects__links'>
                            <a href='https://github.com/klevisdoga/Industry-project' rel='noreferrer' target='_blank'>
                                <img src={github} alt='github icon' />
                                <span>CODE</span>
                            </a>
                            <a href='https://kj-cg.herokuapp.com' rel='noreferrer' target='_blank'>
                                <img src={heroku} alt='heroku icon' />
                                <span>SITE</span>
                            </a>
                        </div>
                    </div>
                    <a href='https://kj-cg.herokuapp.com' rel='noreferrer' target='_blank'><img src={cgPoster} alt='slice poster' className='projects__project-poster'/></a>
                </div>

            </div>

        </div>
    )
}
