import React from 'react';
import './AboutPage.scss';
import { Icons } from './LanguageData'

const AboutPage = () => {
    return (
        <div className='about'>
            <div className='about__me'>
                <h1 className='about__heading'>About Me</h1>
                <p>
                    My name is Klevis Doga, I am a 23 year old Retail Trader turned Full Stack Developer
                    based in Toronto, Ontario. I studied at BrainStation under their Web Development Bootcamp and graduated in July 2022.
                </p>

            </div>
            <div className='about__languages'>
                <h1 className='about__heading'>Languages & Tools</h1>
                <div className='about__languages-icons'>
                    {Icons.map(lang => {
                        return (
                            <a href={lang.url} rel='noreferrer' target='_blank'>
                                <img src={lang.image} alt='language icon' className='about__languages-icon' />
                            </a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AboutPage